.footer {
    display: flex;
    flex-direction: column;
    box-shadow: $shadow;

    .container {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        color: $color-text;
        font-size: $font-size--3;
        font-weight: $font-weight-medium;
        margin: 0 0 10px;
    }

    &__buttons {
        margin-top: 10px;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 330px;
        height: 45px;
        padding: 0;
        margin: 20px 0 0 0;

        svg {
            width: 90px;
            height: 19px;
        }
    }

    &__coordonnees,
    &__horaires {
        padding: 60px 0;
        width: 330px;
    }

    &__coordonnees {
        margin-right: 100px;
    }

    &__horaires {
        margin-right: 70px;
    }

    &__partners {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;

        a {
            display: block;

            &:first-child {
                margin-right: 30px;
            }
        }
    }

    &__menu {
        position: relative;
        padding: 60px 0 60px 50px;
        background-color: $color-light;
        width: 240px;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: -50vw;
            width: 50vw;
            background-color: $color-light;
        }

        ul {
            display: flex;
            justify-content: center;
            align-items: space-between;
            flex-wrap: wrap;
            flex-direction: column;
            width: 100%;
        }

        li {
            position: relative;
            padding: 6px 0;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }

            &:hover, &:focus-within {
                &::before {
                    width: 100%;
                }
            }

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 30px;
                height: 2px;
                background: $color-main;
                transition: width $duration ease-in-out;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {
        &__coordonnees {
            margin-right: 40px;
        }

        &__horaires {
            margin-right: 60px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .footer {
        .container {
            flex-wrap: wrap;
        }

        &__coordonnees, &__horaires {
            padding: 50px 0;
            width: 290px;
        }

        &__coordonnees {
            margin-right: 20px;
        }

        &__horaires {
            margin-right: 0;
        }

        &__partners a {
            display: block;
            width: 50%;

            img {
                width: 100%;
                height: auto;
            }

            &:first-child {
                margin-right: 0px;
            }
        }

        &__button {
            width: 290px;
        }

        &__menu {
            padding: 40px 0;
            width: 100%;

            &::after {
                left: -50vw;
                width: auto;
                z-index: -1;
            }

            ul {
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            li {
                padding: 6px 15px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                    margin-bottom: 5px;
                }

                &::before {
                    left: auto;
                }
            }
        }

    }

}

// 640
@media screen and (max-width: $small) {

    .footer {
        &__coordonnees {
            margin-right: 0;
            padding-top: 50px;
        }

        &__coordonnees, &__horaires {
            padding: 40px 0;
            width: 300px;
            text-align: center;
        }

        &__menu {
            padding: 15px 0;

            ul {
                justify-content: center;
            }
            
            li {
                padding-top: 15px;
                padding-bottom: 15px;
                font-size: $font-size--text-small;

                &::before {
                    display: none;
                }

                &:first-child {
                    padding: 15px;
                }

                &:last-child {
                    padding: 15px;
                }
            }
        }
    }

}