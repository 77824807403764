.home {

    //======================================================================================================
    // Style home
    //======================================================================================================
    &__container--flex {
        position: relative;
        padding: 80px 0 100px 0;

        > .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: -22px;
        }
        
        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100px;
            z-index: -1;
            background: url(/wp-content/themes/forges-les-bains/assets/src/images/motif.png) repeat $color-bg--transparent;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0;
        margin-bottom: 40px;
        padding-bottom: 15px;
        line-height: 1;

        &::after {
            background-color: $color-second;
        }

        .home__title {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        &--right {
            align-items: flex-end;
            margin-top: 53px;
        }

        &--reverse {
            &::after {
                left: auto;
                right: 0;
                background-color: $color-fifth;
            }
        }

        &--white {
            .home__title, .home__subtitle {
                color: $color-white;
            }
        }
    }

    &__title {
        margin: 0 0 40px 0;
        padding-bottom: 10px;
        font-size: 3.75rem;
        font-weight: $font-weight-bold;

        &::after {
            background-color: $color-second;
        }

        &--white {
            color: $color-white;

            &::after {
                background-color: $color-third;
            }
        }
    }

    &__subtitle {
        font-size: $font-size--2;
    }

    &__button {
        height: 45px;
        margin: 10px 0 0 0;
        padding: 12px 20px;
        background-color: $color-second;
        border-color: $color-second;

        &:hover, &:focus {
            background-color: $color-fourth;
            border-color: $color-fourth;
        }
    }


    //======================================================================================================
    // SlideShow
    //======================================================================================================
    &-slideshow {
        position: relative;
        padding-bottom: 80px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 120px;
            z-index: -1;
            background: url(/wp-content/themes/forges-les-bains/assets/src/images/motif.png) repeat $color-bg--transparent;
        }

        > .container {
            position: relative;
        }

        .container {
            &--content {
                position: relative;
                height: 100%;
                box-sizing: border-box;
            }

            &--navigation, &--pagination {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                pointer-events: none
            }
        }

        .slideshow {
            height: 602px;
            width: 100%;
            padding-bottom: 22px;

            img, video {
                position: absolute;
                object-fit: cover;
                object-position: top;
                width: 100%;
                height: 100%;
            }

            &__container {
                display: inherit;
                position: relative;
                width: 100%;
                height: 100%;
            }

            &__content {
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                position: absolute;
                left: 115px;
                bottom: -22px;
                max-width: 600px;
                height: auto;
                padding: 5px 20px;
                background: $color-main;
                color: $color-white;
            }

            &__title {
                font-size: $font-size--3;
                font-weight: $font-weight-medium;
            }

            &--video {
                img {
                    display: none;
                }
            }

            // Navigation (arrow)
            &__navigation {
                position: absolute;
                right: 15px;
                bottom: 0;
                pointer-events: none;
                width: 120px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: space-between;
        
                &__btn {
                    pointer-events: auto;
                    width: 45px;
                    height: 45px;
                    margin: 0;
                    padding: 0;
                    background: $color-main;
                    border: 0;
        
                    &::after {
                        position: relative;
                        font-size: $font-size--text;
                        font-weight: $font-weight-bold;
                        color: $color-white;
                    }
        
                    &:hover, &:focus {
                        background-color: $color-third;
                    }

                    &--prev {
                        left: 0;
                        top: 0;
                    }

                    &--next {
                        right: 0;
                        top: 0;
                    }
                }
            }

            // Pagination (bullet)
            &__pagination {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 15px;
                bottom: 30px;
                z-index: 2;
                width: auto;
        
                .swiper-pagination-bullet {
                    width: 10px;
                    height: 10px;
                    margin: 0 10px 0 0;
                    transition: .25s;
                    background-color: $color-white;
                    border-radius: 5px;
                    opacity: 1;
        
                    &:hover {
                        background-color: $color-main;
                    }
        
                    &-active {
                        width: 40px;
                        background-color: $color-main;
                    }
                }
            }
        
        }
    }


    //======================================================================================================
    // Access
    //======================================================================================================
    .accessh {
        display: none;
        position: relative;
        margin-top: -50px;
        padding-bottom: 40px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 360px;
            z-index: -1;
            background: url(/wp-content/themes/forges-les-bains/assets/src/images/motif.png) repeat $color-white;
        }

        &__list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        } 

        &__item {
            width: 300px;
            margin: 10px;

            &:first-child, &:nth-child(3n+1) {
                margin-left: 0;
            }

            &:nth-child(3n) {
                margin-right: 0;
            }
        }

        &__link {
            display: flex;
            align-items: center;
            padding: 0 20px;
            background-color: $color-white;
            color: $color-main;
            font-size: $font-size--18;
            font-weight: $font-weight-medium;
            text-transform: uppercase;

            svg {
                width: 40px;
                height: 50px;
                margin-right: 20px;
                fill: $color-main;
            }

            &:hover, &:focus {
                background-color: $color-main;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }

        &__icon {
            display: flex;
            align-items: center;
        }
    }

    //======================================================================================================
    // News
    //======================================================================================================
    .news {
        padding: 60px 0;

        &__list {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        &__item {
            position: relative;
            flex: 1;
            margin: 30px 15px;

            &:nth-child(2) {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &--big {
                flex: 1 1 100%;
                margin: 0;

                &:hover, &:focus {
                    .news__infosbig {
                        right: 30px;
                    }
                }

                .news__content {
                    width: 800px;
                    height: 410px;
                }

                .news__title {
                    color: $color-white;
                    font-size: $font-size--3;
                    margin-top: 0;
                }
            }

            &:hover, &:focus {
                .news__more {
                    &::after {
                        width: 60px;
                    }
                }

                .news__content {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        figure {
            line-height: 0;
        }

        &__content {
            overflow: hidden;
            width: 335px;
            height: 170px;

            img {
                transform: scale(1);
                transition: transform $duration ease-in-out;
            }

            &--notimg {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) no-repeat $color-bg--image;
                background-position: center;
            }
        }

        &__infosbig {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 370px;
            padding: 30px;
            background-color: $color-second;
            color: $color-white;
            line-height: 1.5;
            transition: right $duration ease-in-out;
        }

        &__infos {
            line-height: 1.5;
        }

        &__title {
            font-size: $font-size--4;
            font-weight: $font-weight-bold;
            margin: 20px 0;
            color: $color-text;
        }

        &__more {
            margin-top: 20px;
            padding-bottom: 8px;
            text-transform: uppercase;
            color: $color-white;
            font-size: $font-size--text-small;
            font-weight: $font-weight-medium;
            position: relative;
            display: block;

            &::after {
                content:'';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 30px;
                height: 2px;
                background-color: $color-fifth;
                transition: width $duration ease-in-out;
            }

            &--black {
                color: $color-text;

                &::after {
                    background-color: $color-second;
                }
            }
        }
    }


    //======================================================================================================
    // Events
    //======================================================================================================
    .events {
        position: relative;
        margin: 100px 0;
        padding: 50px 0 0 0;
        background-color: $color-main;

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: -100px;
            height: 100px;
            z-index: -1;
            background: url(/wp-content/themes/forges-les-bains/assets/src/images/motif.png) repeat $color-bg--transparent;
        }

        &__container {
            position: relative;
        }

        &__maintitle {
            position: absolute;
            left: 625px;
            top: 60px;
        }

        &__item {
            position: relative;
            margin: 30px 0;
            width: auto;

            &:first-child {
                margin-right: 30px;
            }

            &--big {
                flex: 1 1 100%;
                margin: -70px 0 0 0;

                .events__link {
                    max-width: inherit;
                }

                .events__image {
                    width: 480px;
                    height: 480px;
                    margin-bottom: 0;
                }

                .events__infos {
                    margin: 0 0 30px 30px;
                }

                .events__title {
                    font-size: $font-size--2;
                    font-weight: $font-weight-bold;
                    color: $color-white;
                    margin: 40px 0 20px 0;
                }

                .events__date {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    left: auto;
                    bottom: auto;
                    width: 200px;
                    height: 90px;
                    margin-left: -60px;
                    padding: 17px 20px;
                    background-color: $color-white;
                    color: $color-main;
                    font-weight: $font-weight-bold;
                    transition: margin-left $duration ease-in-out;
        
                    svg {
                        fill: $color-main;
                        margin: 0 15px;
                    }

                    &__day {
                        font-size: 2.5rem;
                    }
        
                    &__month {
                        font-size: 1.375rem;
                        text-transform: uppercase;
                    }
                }

                .events__link {
                    align-items: flex-end;
                }

                &:hover, &:focus {
                    .events__date {
                        margin-left: 0;
                    }
                }
            }

            &:hover, &:focus {
                .events__more {
                    &::after {
                        width: 60px;
                    }
                }

                .events__content {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        &__link {
            display: flex;
            align-items: center;
            position: relative;
            max-width: 520px;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 40px;
                background-color: $color-white;
                z-index: -1;
            }
        }

        &__image {
            overflow: hidden;
            width: 200px;
            height: 200px;
            flex: none;
            margin-bottom: 30px;

            img {
                transform: scale(1);
                transition: transform $duration ease-in-out;
            }

            &--notimg {
                background: url(/wp-content/themes/noyau/assets/images/icon-events.png) no-repeat $color-bg--image;
                background-position: center;
            }
        }

        &__infos {
            line-height: 1.5;
            margin: 0 30px;
        }

        &__title {
            font-size: $font-size--4;
            font-weight: $font-weight-bold;
            margin: 20px 0;
            color: $color-text;
        }

        &__date {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            bottom: 0;
            min-width: 76px;
            height: 76px;
            padding: 13px 15px;
            background-color: $color-second;
            color: $color-white;
            font-weight: $font-weight-bold;
            box-sizing: border-box;

            svg {
                width: 9px;
                height: 16px;
                fill: $color-white;
                margin: 0 10px;
            }

            p {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                line-height: 1;
            }

            &__day {
                font-size: 2.25rem;
            }

            &__month {
                font-size: 1.25rem;
                text-transform: uppercase;
            }
        }

        &__more {
            margin-top: 20px;
            padding-bottom: 8px;
            text-transform: uppercase;
            color: $color-white;
            font-size: $font-size--text-small;
            font-weight: $font-weight-medium;
            position: relative;
            display: block;

            &::after {
                content:'';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 30px;
                height: 2px;
                background-color: $color-third;
                transition: width $duration ease-in-out;
            }

            &--black {
                color: $color-text;

                &::after {
                    background-color: $color-main;
                }
            }
        }

        &__nav {
            position: absolute;
            bottom: -22px;
            right: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 120px;
            z-index: 1;

            &__button {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                padding: 0;
                width: 45px;
                height: 45px;
                background-color: $color-second;
                border: 2px solid $color-second;

                &:hover, &:focus {
                    background-color: $color-fourth;
                    border-color: $color-fourth;
                }

                svg {
                    width: 45px;
                    height: 45px;
                }
            }
        }

        &__buttons {
            position: relative;
            bottom: -22px;
        }
    }


    //======================================================================================================
    // AdminAreas
    //======================================================================================================
    .admin-blocs {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .bloc {
            display: flex;
            height: 610px;
            position: relative;

            &__wrapper {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                height: 100%;

                &--video {
                    cursor: pointer;

                    &::before {
                        content: '\f126';
                        font: normal 34px/1 dashicons;
                        position: absolute;
                        z-index: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        color: $color-white;
                    }
                }

                > .container {
                    width: 1200px;
                }

            }

            &__image {
                position: absolute;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            &__container {
                position: absolute;
                left: auto;
                bottom: 40px;
                z-index: 1;
            }

            &__maintitle {
                margin-bottom: 40px;
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8));
                
                &::after {
                    background-color: $color-white;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 430px;
                padding: 30px;
                color: $color-text;
                background-color: $color-white--rgba;
            }

            &__title {
                font-size: $font-size--3;
                font-weight: $font-weight-medium;
                margin-bottom: 10px;
            }

            &__more {
                margin-top: 20px;
                padding-bottom: 8px;
                text-transform: uppercase;
                color: $color-text;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                position: relative;
                display: block;
    
                &::after {
                    content:'';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 30px;
                    height: 2px;
                    background-color: $color-fourth;
                    transition: width $duration ease-in-out;
                }

                &:hover, &:focus {
                    &::after {
                        width: 60px;
                    }
                }
            }

            .popup-area {
                display: flex;
                justify-content: center;
                align-items: center;
                position: fixed;
                left: 0;
                top: 0;
                z-index: 20;
                width: 100%;
                height: 100%;
                opacity: 0;
                visibility: hidden;
                background: $color-black--rgba;

                &.show-popup {
                    visibility: visible;
                    opacity: 1;
                }

                .accordion {
                    height: 0;
                    color: $color-white;

                    svg {
                        fill: $color-white;
                    }
                }

                .close-area-popup {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    position: absolute;
                    top: 40px;
                    right: calc((100% - 1170px)/2);
                    width: 40px;
                    height: 40px;
                    background: $color-white;
                    border-radius: 50px;

                    &::before {
                        content: '\f335';
                        font: normal 30px/1 'dashicons';
                    }
                }
            }
        }
    }


    //======================================================================================================
    // Docs
    //======================================================================================================
    .docs {
        width: 50%;

        &__container {
            position: relative;
        }

        .swiper-wrapper {
            display: flex;
            align-items: stretch;
        }
        
        .swiper-slide {
            padding: 10px;
        }

        &__nav {
            position: absolute;
            bottom: 0;
            left: 312px;
            width: 120px;
            height: 45px;
            display: flex;
            justify-content: space-between;

            &__button {
                width: 45px;
                height: 45px;
                margin: 0;
                padding: 0;

                svg {
                    width: 45px;
                    height: 45px;
                }
            }
        }

        &__item {
            display: flex;
            align-items: center;
        }

        &__image {
            position: relative;
            width: 200px;
            height: 271px;
            flex: none;
            filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15));

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }

            &--no-image {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-bg--image;

                svg {
                    width: 40%;
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 0 0 0 30px;
        }

        &__title {
            margin: 0 0 30px 0;
            font-size: $font-size--3;
            font-weight: $font-weight-medium;
            color: $color-text;
            word-break: break-word;
        }

        &__maintitle {
            margin-left: 10px;

            &::after {
                background-color: $color-third;
            }
        }

        &__infos {
            margin-bottom: 30px;
        }

        &__filesize {
            text-transform: uppercase;
        }

        &__btns {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &__btn {
            margin-top: 20px;
            padding-bottom: 6px;
            text-transform: uppercase;
            color: $color-text;
            font-size: $font-size--text;
            font-weight: $font-weight-medium;
            position: relative;
            display: block;
            text-decoration: none;

            &::after {
                content:'';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 30px;
                height: 2px;
                background-color: $color-fourth;
                transition: width $duration ease-in-out;
            }

            &:first-child {
                margin-top: 0;
            }

            &:hover, &:focus {
                &::after {
                    width: 60px;
                }
            }
        }

        &__buttons {
            margin-top: 40px;
        }

        &__button {
            margin: 0 0 0 10px;
            background-color: $color-main;
            border-color: $color-main;

            &:hover, &:focus {
                background-color: $color-third;
                border-color: $color-third;
            }
        }
    }


    //======================================================================================================
    // Connect
    //======================================================================================================
    .connect {
        width: 50%;
        padding-left: 115px;

        &__list {
            padding: 40px;
            background-color: $color-bg--neutral;
        }

        &__item {
            margin-bottom: 60px;
            line-height: 1.2;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__link {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                width: 48px;
                height: 48px;
                margin-right: 27px;
            }

            p {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                text-transform: uppercase;
                font-size: $font-size--18;
            }

            &__title {
                font-weight: $font-weight-bold;
            }

            &:hover, &:focus {
                color: $color-second;
            }
        }
    }


    .contactsmaps {
        padding: 0;
        background-color: $color-second;

        > .container {
            position: relative;
            margin-bottom: 0;
        }
        
        &::after {
            display: none;
        }
    }


    //======================================================================================================
    // Contacts
    //======================================================================================================
    .contacts {
        width: 330px;
        padding: 80px 0 110px 0;
        color: $color-white;

        .home__title {
            margin-bottom: 60px;
            &::after {
                background-color: $color-fifth;
            }
        }

        &__text {
            font-size: $font-size--text-medium;
            font-weight: $font-weight-medium;
        }

        &__buttons {
            margin: 10px 0 67px 0;
        }

        &__button {
            width: 100%;
            margin-top: 30px;
            background-color: $color-fifth;
            border-color: $color-fifth;

            &:hover, &:focus {
                background-color: $color-fourth;
                border-color: $color-fourth;
            }
        }

        &__label {
            margin-bottom: 20px;
            font-size: $font-size--3;
            font-weight: $font-weight-bold;
        }

        &__select {
            display: flex;
        }
        
        &__dropdown {
            max-width: 260px;
            height: 45px;
            padding: 11px;
            background-color: $color-white;
            transition: all 0.25s ease-in-out;

            &:focus {
                border: 2px solid $color-main;
            }
        }

        &__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 0 20px;
            padding: 0;
            width: 50px;
            height: 45px;
            background-color: $color-fifth;
            border-color: $color-fifth;
            font-size: $font-size--text;

            &:hover, &:focus {
                background-color: $color-fourth;
                border-color: $color-fourth;
            }
        }
    }


    //======================================================================================================
    // Map
    //======================================================================================================
    .map {
        position: relative;
        z-index: 1;
        width: auto;
        height: 782px;

        &__content {
            img {
                position: absolute;
                top: 0;
                right: -145px;
                z-index: -1;
            }
        }

        &__item {
            position: absolute;
        }

        &__link {
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;

            &:hover, &:focus {
                .map__label {
                    opacity: 1;
                }

                svg circle[fill="#5f9581"] {
                    fill: $color-fifth;
                    transition: fill $duration ease-in-out;
                }
            }
            
            svg {
                margin-top: 10px;
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.4));
            }
        }

        &__label {
            position: relative;
            bottom: 0;
            padding: 8px 15px;
            background-color: rgba(255, 255, 255, 0.9);
            border-radius: $btn-border-radius;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            font-size: $font-size--text;
            color: $color-fifth;
            text-transform: uppercase;
            opacity: 0;
            pointer-events: none;
            transition: opacity $duration ease-in-out;
        }

        #mapID1 {
            top: 280px;
            left: -85px;
        }

        #mapID2 {
            top: 230px;
            right: 28px;
        }

        #mapID3 {
            left: -197px;
            top: 317px;
        }

        #mapID4 {
            top: 303px;
            left: -326px;
        }

        #mapID5 {   
            top: 490px;
            left: -165px;
        }

        #mapID6 {
            top: 535px;
            left: -196px;
        }

        &__footer {
            position: absolute;
            bottom: 130px;

            .button {
                background-color: $color-white;
                color: $color-fifth;

                &:hover, &:focus {
                    background-color: $color-fifth;
                    color: $color-white;
                    border-color: $color-fifth;
                }
            }
        }
    }


}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .home-slideshow .slideshow {
        height: 492px;
    }

    .home {

        &-slideshow .slideshow__content {
            left: 10px;
        }

        .accessh {
            display: block;
        }

        .news {
            &__item {
                margin: 30px 10px;

                &--big {
                    margin: 0;
                }
            }

            &__content {
                width: 300px;
                height: 152px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }
        }

        .events {
            &__item {
                &--big {
                    .events__link {
                        max-width: inherit;
                    }
                }
            }

            &__link {
                max-width: 460px;
            }

            &__maintitle {
                left: 520px;
            }
        }

        .connect {
            padding-left: 50px;
        }

        .docs__content {
            padding: 0 0 0 20px;
        }

        .map__content img {
            width: 740px;
            height: 707px;
        }

        .contacts {
            padding: 65px 0 75px;

            .home__title {
                margin-bottom: 30px;
            }
        }

        .map {
            height: 707px;

            #mapID1 {
                top: 240px;
                left: -35px;
            }
    
            #mapID2 {
                top: 194px;
                right: 8px;
            }
    
            #mapID3 {
                left: -142px;
                top: 277px;
            }
    
            #mapID4 {
                top: 260px;
                left: -256px;
            }
    
            #mapID5 {   
                top: 430px;
                left: -110px;
            }
    
            #mapID6 {
                top: 469px;
                left: -146px;
            }

            &__footer {
                bottom: 100px;
                right: 20px;
            }
    
        }

    }
    

}


// 960
@media screen and (max-width: $medium) {

    .home-slideshow {
        position: relative;

        &::after {
            display: none;
        }

        .slideshow {
            height: 392px;

            &--video {
                height: 370px;

                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }
        }
    }
    

    .home-slideshow .slideshow__content {
        max-width: 465px;
    }

    .home-slideshow .slideshow__navigation {
        right: 10px;
    }

    .home {
        .accessh::after {
            height: 760px;
        }

        .accessh__item {
            margin: 10px;

            &:first-child, &:nth-child(3n+1) {
                margin-left: 10px;
            }

            &:nth-child(3n) {
                margin-right: 10px;
            }

            &:nth-child(odd) {
                margin-left: 0;
            }

            &:nth-child(even) {
                margin-right: 0;
            }
        }

        .news {

            &:hover, &:focus {
                .news__infosbig {
                    right: 0;
                }
            }

            &__infosbig {
                position: relative;
                width: 100%;
            }

            &__list {
                justify-content: flex-start;
            }

            &__item:nth-child(2) {
                margin-left: 0;
            }

            &__item {
                flex: inherit;
                width: 48%;

                &--big {
                    width: 100%;

                    .news__content {
                        width: 620px;
                        height: 320px;
                    }
                }

                &:nth-child(3) {
                    margin-right: 0;
                }

                &:last-child {
                    margin-left: 0;
                    margin-top: 0;
                }
            }

        }

        .events {
            &__maintitle {
                position: relative;
                top: auto;
                left: auto;
                margin-bottom: 40px;
            }  
            
            &__item {
                &:first-child {
                    margin-right: 0;
                }

                &--big {
                    margin: 0;

                    .events__image {
                        width: 300px;
                        height: 300px;
                    }
                }

                .events__infos {
                    margin: 0 0 0 30px;
                }

                .events__title {
                    margin: 30px 0 20px;
                    line-height: 1;
                }
            }

            &__image img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }

            &__link {
                max-width: inherit;
            }
                    
        }

        .admin-blocs .bloc {
            height: 450px;
        }

        &__container--flex>.container {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }

        .docs, .connect {
            width: 100%;
        }

        .connect {
            padding-left: 0;
            padding-top: 100px;
        }

        .contacts {
            padding: 100px 0 40px;
            width: 100%;

            &__buttons {
                margin: 10px 0 30px;
                display: flex;
                align-items: center;
            }

            &__button {
                width: 48%;
                
                &:first-child {
                    margin-right: 2%;
                }
            }

            &__dropdown {
                max-width: 550px;
            }
        }

        &__header {
            &--right {
                align-items: flex-start;
                margin-top: 50px;
            }

            &--reverse:after {
                left: 0;
                right: auto;
            }            
        }

        .map {
            width: 100%;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: -50vw;
                right: -50vw;
                height: 470px;
                background: linear-gradient(-180deg,#507d54,rgba(80,125,84,0) 18.39%);
                z-index: -1;
            }

            &__content {
                img {
                    width: 660px;
                    height: 630px;
                    right: 0;
                }
            }

            &__list {
                position: absolute;
                left: 0;
                top: 230px;
                display: flex;
                flex-wrap: wrap;
            }

            &__item {
                position: initial;
                width: 50%;
                margin: 15px 0;
            }

            &__link {
                flex-direction: row;
                justify-content: flex-start;

                svg {
                    margin-top: 0;
                }
            }

            &__label {           
                bottom: 0;
                opacity: 1;
                pointer-events: inherit;
                margin-left: 20px;
            }

            &__footer {
                bottom: 135px;
                left: 0;

                .button {
                    width: 300px;
                }
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .home-slideshow {
        .slideshow { 
            height: 350px;
            padding-bottom: 97px;

            &--video {
                height: 250px;
                padding-bottom: 22px;
            }
            
            &__content {
                left: 10px;
                right: 10px;
                max-width: inherit;
                padding: 10px 15px;
                text-align: center;
            }

            &__title {
                font-size: $font-size--text;
            }

            &__navigation {
                right: auto;
                left: 32%;
            }
        }
    }

    .home {
        &__title {
            display: flex;
            justify-content: center;
            text-align: center;
            
            &::after {
                left: auto;
            }
        }

        &__button {
            width: 100%;
        }

        &__header {
            align-items: center;

            &::after {
                left: auto;
            }
        }

        .accessh {
            &:after {
                height: 800px;
            }

            &__item {
                margin-left: 0 !important;
                margin-right: 0!important;
            }
        }

        .news {
            &__item { 
                width: 100%;
                margin: 10px 0;
                text-align: center;

                &--big {
                    text-align: left;

                    .news__content {
                        width: 300px;
                        height: 150px;
                    }

                    .news__more {
                        justify-content: flex-start;

                        &::after {
                            left: 0;
                        }
                    }
                }

                &:last-child {
                    margin-top: 10px;
                }
            }

            &__more {
                display: flex;
                align-items: center;
                justify-content: center;

                &::after {
                    left: auto;
                }
            }

            &__buttons {
                margin-top: 20px;
            }
        }

        .events {
            &__link {
                flex-wrap: wrap;
                padding: 0 15px
            }

            &__infos {
                margin: 0 !important;
            }

            &__item {
                &--big {
                    .events__date {
                        margin-left: 0;
                        margin-top: -45px;
                        top: auto;
                    }

                    .events__link {
                        height: auto;
                    }
                }

                .events__title {
                    text-align: center;
                }

                .events__infos {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

            &__link {
                justify-content: center;
                height: 400px;
            }

            &__date {
                top: 160px;
                left: auto;
            }

            &__more {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0;
                margin-bottom: 50px;            

                &::after {
                    left: auto;
                }
            }

            &__nav {
                bottom: 65px;
                right: 99px;
            }
        }

        .admin-blocs {
            .bloc {
                height: auto;
                background-color: $color-light;

                &__image {
                    height: 280px;
                    position: relative;
                }

                &__container {
                    position: relative;
                    left: auto;
                    bottom: auto;
                }

                &__maintitle {
                    position: absolute;
                    top: -185px;
                }

                &__content {
                    background-color: transparent;
                    width: auto;
                    text-align: center;
                    align-items: center;
                }

                &__more {
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    &::after {
                        left: auto;
                    }
                }
            }
        }

        .docs {
            .swiper-slide {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0;
            }

            &__content {
                padding: 20px 0 0 0;
                align-items: center;
                text-align: center;
            }

            &__btns {
                align-items: center;
            }

            &__btn {
                display: flex;
                align-items: center;
                justify-content: center;

                &::after {
                    left: auto;
                }
            }

            &__nav {
                bottom: 70px;
                left: 30%;
            }

            &__buttons {
                margin-top: 90px;
            }

            &__button {
                margin: 0;
            }
        }

        .connect {
            &__list {
                padding: 30px 20px;
            }

            &__item {
                margin-bottom: 30px;
            }

            &__link {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;

                p {
                    align-items: center;
                }

                svg {
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }
        }

        .contacts {
            &__text {
                text-align: center;
            }

            &__buttons {
                flex-wrap: wrap;
            }

            &__button {
                width: 100%;
                margin-top: 30px;

                &:first-child {
                    margin-right: 0;
                }
            }

            &__dropdown {
                max-width: 230px;
            }
        }

        .map {
            height: 930px;

            &__content img {
                width: 640px;
                height: 611px;
                right: -240px;
                top: 320px;
            }

            &::after {
                top: 320px;
            }

            &__item {
                width: 100%;
            }

            &__footer {
                bottom: 80px;
            }
        }
    }

}
