.header {
    position: relative;
    z-index: 11;
    box-shadow: $shadow;

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: calc((100% - 1170px) / 2);
        background-color: $color-light;
    }

    .container {
        position: relative;
        padding-left: ($gl-gutter * 0.5);
    }

    &--container {
        position: relative;
    
        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 285px;
            background-color: $color-light;
            z-index: -1;
        }
    }

    &-top {
        position: relative;
        display: flex;
        align-items: center;
        padding: 25px 0;
    
        .search-popup__content {
            position: relative;
            z-index: 11;
        }
    
        .search-container {
            box-shadow: $shadow;
    
            .searchInput {
                width: 595px;
                height: 45px;
                padding: 12px 0 12px 15px;
                background-color: $color-white;
                border: 0;
            }
    
            .search-svg {
                width: 45px;
                background-color: $color-white;
    
                svg {
                    width: auto;
                    height: 100%;
                    fill: $color-main;
                }
            }
     
        }
    
        .searchResult {
            position: absolute;
            z-index: 11;
            top: 45px;
            width: 100%;
    
            li a {
                padding: 15px 115px 15px 24px;
            }
        }
    }
}

.site-identity {
    margin-right: auto;
}

.identity{
    display: block;

    &__title {
        margin: 0;
        line-height: 0;
        display: block;
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link {
    display: none;
}

.tools {
    margin-left: 30px;

    .container--tools {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
    }
}

.tool {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    margin: 0;
    padding: 0;
    background-color: $color-main;
    border: 0;
    
    svg {
        fill: $color-white;
    }

    &:hover, &:focus {
        cursor: pointer;
        background-color: $color-third;
    }

    &__translate {
        svg {
            width: 24px;
            height: 24px;
        }
    }

    &__accessibility {
        svg {
            width: 45px;
            height: 45px;
        }
    }

    &__profile {
        svg {
            width: 29px;
            height: 22px;
        }
    }

    &__search {
        display: none;

        svg {
            width: 22px;
            height: 24px;
        }
    }
}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: all $duration;

                        &:hover {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Acces aside
//======================================================================================================
.menu-left {
    position: absolute;
    top: 215px;
    z-index: 15;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
    filter: drop-shadow(0 -5px 10px rgba(0,0,0,.25));

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        width: 88px;
        background-color: $color-main;
        transition: width $duration ease-in-out;
        filter: drop-shadow(0px -5px 10px rgba(0, 0, 0, 0.25));
    }

    &:hover, &:focus-within {
        pointer-events: auto;

        &::after {
            width: 318px;
        }

        .access__link {
            pointer-events: auto;
        }
        
        .access__title {
            opacity: 1;
            transition: opacity $duration ease-in-out;
        }
    }

    .access {
        &__list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &__item {
            width: 100%;
        }

        &__link {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0 25px 0 0;
            background-color: $color-bg--transparent;

            &:hover, &:focus {
                background-color: $color-third;
            }
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 88px;
            height: 78px;
            pointer-events: auto;

            svg {
                width: 40px;
                height: 50px;
                fill: $color-white;
            }
        }

        &__title {
            font-size: 1.125rem;
            font-weight: $font-weight-medium;
            text-transform: uppercase;
            color: $color-white;
            opacity: 0;
            transition: none;
        }
    }
}

@media screen and (min-width: 1670px) {
    .menu-left {
        pointer-events: auto;

        .access__link {
            pointer-events: auto;
        }

        &::after {
            width: 318px;
        }
       
        .access__title {
            opacity: 1;
            pointer-events: unset;
        }
    }
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 245px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .identity {
        svg {
            width: 300px;
            height: 72px;
        }
    }

    .header::after {
        width: calc((100% - 960px) / 2);
    }

    .header--container:after {
        width: 232px;
    }

    .header-top .search-container .searchInput {
        width: 465px;
    }    

    .menu-left {
        display: none;
        opacity: 0;
    }

}


// 960
@media screen and (max-width: $medium) {

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .tool__search {
        display: flex;
        align-items: center;
        width: auto;
        margin-right: 30px;
        padding-right: 15px;

        svg {
            width: 45px;
            height: 45px;
        }
    }

    .header::after, .header--container:after {
        display: none;
    }

    .header-top .search-popup__content {
        display: none;
    }

    .header .container {
        padding-left: 10px;
    }

    #nav-main {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            right: -50vw;
            top: 0;
            bottom: 0;
            left: -50vw;
            background-color: #e6e6e6;
            z-index: -1;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    .tools-view {
        .container--tools-view {
            .profils {
                margin-top: 80px;
                margin-right: 35px;
            }
        }
    }

    .header-top {
        flex-wrap: wrap;
    }

    .tools {
        margin-left: 0;
        margin-top: 25px;
    }

    .tool__search {
        width: 225px;
    }

}
