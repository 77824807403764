.cover {
    position: relative;

    &__image-wrapper {
        width: 100%;
        height: 450px;
        background: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background: $color-second;
        padding: 85px 0 70px 0;
        color: $color-white;
    }

    &__title {
        color: $color-white;
        margin: 0 0 10px;
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight-bold;
            font-size: $font-size--text-medium;
            color: $color-white;
        }
    }

    &__intro {
        color: $color-white;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 380px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 255px;
        }
    }

}
