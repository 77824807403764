//======================================================================================================
// Contact base
//======================================================================================================
// Component badge
.contact-badge {
    display: flex;
    align-items: center;

    &__icon {
        flex: none;
        width: 26px;
        height: 26px;
        margin-right: 8px;
        fill: $color-text;
    }

    &__link {
        display: flex;
        align-items: center;
        text-decoration: underline;
        word-break: break-all;
        transition: none;

        &:hover, &:focus {
            text-decoration: none;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }

        svg {
            width: 20px;
            height: 20px;
            fill: $color--card;
            transition: fill $duration ease-in-out;
        }

        &--underlinenone {
            text-decoration: none;
        }
    }

    &__additional {
        margin-top: 10px;
        
        &--no-margin {
            margin-top: 0;
        }
    }

    &--icon-top {
        align-items: flex-start;

        .contact-badge__icon {
            margin-top: -2px; // fix for margin on svg
        }
    }

    &--secondary {
        padding: 5px 0;

        .contact-badge__icon {
            box-sizing: content-box;
            padding: 5px;
            margin-right: 12px;
            border: 2px solid $color-second;
            background-color: $color-second;
            border-radius: $border-radius--round;
            fill: $color-white;
            transition: all $duration ease-in-out;
        }
    }
}

.card__container {
    &:hover, &:focus {
        .contact-badge {
            &__icon {
                background-color: $color-white;
                border-color: $color-white;
                fill: $color-second;
            }
            
            &:hover, &:focus {
                .contact-badge__icon {
                    background-color: $color-bg--transparent;
                    fill: $color-white;
                }
            }

            &__link svg {
                fill: $color-white;
            }
        }
    }
}


//======================================================================================================
// Contact Single
//======================================================================================================
.contact-single { 

    // Container
    .contact-container {
        padding-top: 20px;
        padding-bottom: 20px;
    }

}
