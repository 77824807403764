#nav-main {
    position: relative;
    z-index: 10;

    .menu__nav__list {
        display: flex;
        justify-content: space-between;
        transition: $duration ease-out;
    } 

    .nav-main-item {

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: flex-start;
            }
        }

        &:last-child {
            .nav-main-link {
                font-weight: $font-weight-bold;
            }
        }

        &:hover, &:focus-within {
            .nav-main-link::after {
                width: 100%;
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 30px;
            color: $color-text;
            font-size: 1.375rem;
            text-transform: uppercase;

            &::after {
                content: "";
                position: absolute;
                bottom: 15px;
                width: 40px;
                height: 2px;
                background-color: $color-third;
                transition: width $duration ease-in-out;
            }

            &:hover, &:focus {
                &::after {
                    width: 100%;
                }
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 0 0 50px 0;
            

            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: -50vw;
                right: -50vw;
                background-color: $color-main--rgba;
                z-index: -1;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                width: 100%;
                padding-left: 100px;
            }

            .nav-child-item {
                display: flex;
                flex-direction: column;
                width: calc(100%/3);
                padding: 0 1rem;

                > a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-top: 40px;
                    margin-bottom: 10px;
                    padding: 10px 0;
                    color: $color-white;
                    font-size: $font-size--text-medium;
                    font-weight: $font-weight-bold;

                    &::before {
                        content: "";
                        display: inherit;
                        position: absolute;
                        bottom: 0;
                        width: 40px;
                        height: 2px;
                        background: $color-third;
                        transition: all $duration;
                    }

                    &:hover, &:focus {
                        &::before {
                            width: 170px;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                padding: 0;
                line-height: initial;

                > a {
                    display: flex;
                    margin: 0;
                    padding: 5px 0;
                    color: $color-white;
                    font-size: $font-size--text-small;
                    font-weight: $font-weight;

                    &:hover, &:focus {
                        padding-left: 10px;
                    }
                } 
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 10px 20px;
    color: $color-text;
    font-size: 2.125rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    justify-content: center;

    .navicon {
        position: relative;
        display: block;
        height: 5px;
        top: 0;
        width: 30px;
        margin-right: 15px;
        background: $color-text;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-text;
            transition: all $duration ease-out;
        }

        &:before {
            top: 9px;
        }

        &:after {
            top: -9px;
        } 
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {
        .nav-main-item {
            .nav-main-link {
                padding: 30px 10px;
            }
        }
    }

    #nav-main .nav-main-item .sub-menu ul {
        padding-left: 0;
        margin: 0 -1rem;
    }

}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        position: relative;

        .container--nav {
            position: relative;
        }

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: 71px;
            left: 0;
            right: 0;
            margin: auto -15px;
            background: $color-main;
        }

        .nav-main-item {

            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 20px;
                border-bottom: 1px solid $color-white;
                color: $color-white;
                text-transform: uppercase;

                &:first-child {
                    padding: 0 20px;
                }

                &:after {
                    display: none;
                }
            }

            &:last-child {
                .nav-main-link {
                    border-bottom: 0;

                    &:after {
                        width: 0;
                        height: 0;
                        background: $color-bg--transparent;
                    }
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {
                background: $color-bg--neutral;
                box-shadow: 0 0 1px rgb(27 35 54 / 8%), 1px 1px 10px rgb(27 35 54 / 8%);

                .nav-main-link {
                    border-bottom: 1px solid $color-gray;
                    color: $color-main;
                }

                .sub-menu {
                    display: flex;
                    position: relative;
                    padding: 5px 10px;
                    box-shadow: none;

                    .nav-drop {
                        column-count: 1;
                        margin: 0;
                        padding: 1rem 0;
                    }

                    .nav-grandchild-item {
                        a {
                            padding: 10px;
                        }
                    }

                    li:last-child {
                        a {
                            border-bottom: 0;

                            &::before {
                                display: none;
                            }

                            &::after {
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
        }
    }

    #nav-main .nav-main-item .sub-menu {
        &::before {
            display: none;
        }
        
        .nav-child-item {
            padding: 0.5rem 1rem;
            width: 100%;
            
            > a {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                margin-top: 0;
                margin-bottom: 0;
                padding: 0;
                color: $color-text;
                font-size: 1.25rem;
                font-weight: 700;
    
                &::before {
                    display: none;
                }
            }
        }

        .nav-grandchild-item > a {
            color: $color-text;
        }
    }
    
}


// 640
@media screen and (max-width: $small) {

}
