.breadcrumb {
    position: relative;

    ul  {
        display: block;
        position: absolute;
        top: -25px;
        min-height: 50px;
        padding: 12px 20px;
        background-color: $color-main;
    }

    li {
        position: relative;
        display: inline;
        padding: 0 15px 0 0;
        color: $color-white;
        line-height: 1.75;
        text-transform: uppercase;
        font-weight: $font-weight-medium;
        font-size: $font-size--text-small;

        &:first-child { 
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        a {
            &:hover, &:focus {
                text-decoration: underline;
                color: $color-white;
            }
        }
    }

    svg {
        position: relative;
        display: inline;
        margin-right: 15px;
        width: 7px;
        height: 12px; 
        fill: $color-white;       
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        ul {
            width: 620px;
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        ul {
            top: -70px;
            width: 300px;
        }

        li {
            margin-right: 0;
            padding: 0;
        } 
        
        svg {
            margin: 0 5px;
        }
    }

}
